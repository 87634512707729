import { request } from "@network/index";
import { RequestStationSearch, ResponseStationSearch } from "./types";

export interface RequestCustomerStation {
  topLeftLat: number;
  topLeftLon: number;
  bottomRightLat: number;
  bottomRightLon: number;
  curLatitude: number;
  curLongitude: number;
  sido?: string;
  gugun?: string;
  chargerBrand?: string | null;
  chargerType?: ChargerType[] | null;
  minChargeSpeed?: number;
  maxChargeSpeed?: number;
  freeParkingOnly?: boolean;
  freeEntryOnly?: boolean;
  allDayOpenOnly?: boolean;
}

export type ChargerType = "dcCombo" | "dcDemo" | "acHigh" | "acStandard";

export interface Rows<T> {
  count?: number;
  rows: T[];
}

export interface Row<T> {
  row: T;
}

export interface ResponseCustomerStation {
  // TODO 현재 response 경도 위도 string으로 받아짐
  latitude: number;
  longitude: number;
  distance: number;
  usableLateChargerTotalCount: number;
  usableFastChargerTotalCount: number;
  status: StationStatus;
  stationBrand: StationBrandCode;
  stations: Station[];
}

export enum StationStatus {
  InUse = "inUse",
  CanUse = "canUse",
  Maintenance = "maintenance",
}

export enum StationBrandCode {
  한국전기차충전서비스 = "HE",
  차지비 = "PI",
  파워큐브 = "PW",
  환경부 = "ME",
  한국환경공단 = "KN",
  에스트래픽 = "ST",
  대영채비 = "CV",
  제주도청 = "JD",
  에버온 = "EV",
  지에스커넥트 = "GN",
  전주시 = "JJ",
  클린일렉스 = "KL",
  제주전기자동차서비스 = "JE",
  한국전력 = "KP",
  한국전력공사 = "KEP",
  이카플러그 = "ECP",
  에스케이렌터카주식회사 = "SKR",
  휴맥스이브이 = "HM",
  정읍시 = "JU",
  익산시 = "IK",
  차지인 = "EZ",
  매니지온 = "MO",
  군포시 = "GP",
  삼척시 = "SC",
  소프트베리 = "SB",
  나이스차저 = "NT",
  울산시 = "US",
  스타코프 = "SF",
  삼성EVC = "SS",
  타디스테크놀로지 = "TD",
  LG헬로비전 = "LH",
  LG유플러스 = "LU",
  한국전기차인프라기술 = "KE",
  씨어스 = "CU",
  부안군 = "BA",
  태백시 = "TB",
  중앙제어 = "JA",
  제주에너지공사 = "JEC",
  에스케이에너지 = "SK",
  이지차저 = "EC",
  플러그링크 = "PL",
  레드이엔지 = "RE",
  파킹클라우드 = "PC",
  스칼라데이터 = "SD",
  딜라이브 = "DL",
  신세계아이앤씨 = "IN",
}

export interface Station {
  address: string;
  addressDetail: string;
  fastChargerTotalCount: number;
  isAllDayOpen: boolean;
  isBookMarked: boolean;
  isFreeEntry: boolean;
  isFreeParking: boolean;
  lateChargerTotalCount: number;
  stationBrand: StationBrandCode;
  stationId: string;
  stationName: string;
  status: StationStatus;
  usableFastChargerCount: number;
  usableLateChargerCount: number;
}

/** 충전소 검색 */
export const requestStation = async (params: RequestCustomerStation) => {
  const response = await request<
    RequestCustomerStation,
    Rows<ResponseCustomerStation>
  >({
    method: "get",
    url: "/customer/stations/socar",
    requestParams: params,
  });

  return response?.data?.rows;
};

/** 주소 검색 */
export const requestSearchStation = async ({
  searchType,
  keyword,
  curLatitude,
  curLongitude,
  page = 1,
  pageSize = 30,
}: RequestStationSearch) => {
  try {
    const response = await request<
      RequestStationSearch,
      Rows<ResponseStationSearch>
    >({
      method: "get",
      url: "/customer/stations/search/socar",
      requestParams: {
        searchType,
        keyword,
        curLatitude,
        curLongitude,
        page,
        pageSize,
      },
    });

    const result = response?.data?.rows;

    return {
      rows: result,
      nextPage: page + 1,
      isLast: result?.length < pageSize,
      count: response?.data?.count,
    };
  } catch (e) {
    console.error("REQUEST ADDRESS", e);
    throw e;
  }
};

export interface DistrictsType {
  district: string;
  latitude: number;
  longitude: number;
}

export interface ResponseStationDistricts {
  sido: DistrictsType;
  gugun: DistrictsType[];
}

export const requestStationDistricts = async () => {
  const response = await request<null, Row<ResponseStationDistricts[]>>({
    method: "get",
    url: "/customer/stations/locations",
  });

  return response?.data?.row;
};

export interface ChargerDetailResponse {
  stationId: string;
  latitude: number;
  longitude: number;
  stationName: string;
  stationBrandCode: StationBrandCode;
  stationBrandName: string;
  isFreeEntry: boolean;
  status: StationStatus;
  address: string;
  addressDetail?: string;
  lateChargerTotalCount: number;
  usableLateChargerCount: number;
  fastChargerTotalCount: number;
  usableFastChargerCount: number;
  chargerTypeName: string;
  isBookMarked: boolean;
  isFreeParking: string;
  businessHoursInfo: string;
  usageInfo: string;
  chargerList: Charger[];
  cpoContact: string;
}

export interface Charger {
  stationId: string;
  stationName: string;
  cpoId: string;
  cpoName: string;
  stationLocation?: string;
  chargerId: string;
  connectorId: string;
  chargerInterfaceType: string;
  simpleCode: string;
  chargerNickname?: string;
  chargerSpeedType: "slow" | "rapid";
  chargerCapacity: string;
  status: StationStatus;
  chargerTypeDescription: string;
  isRemoteStart: boolean;
  isRemoteStop: boolean;
  usedAt?: string;
}

// NOTE : 충전소 상세 정보
export const requestChargerDetail = async (
  stationId: string,
  stationBrand: StationBrandCode,
) => {
  const response = await request<
    { stationId: string; stationBrand: string },
    Row<ChargerDetailResponse>
  >({
    method: "get",
    url: `/customer/stations/detail`,
    requestParams: {
      stationId,
      stationBrand,
    },
  });

  return response?.data?.row;
};

export interface MarkerSearch {
  curLatitude?: number;
  curLongitude?: number;
  latitude: number;
  longitude: number;
}

export const requestChargerSearchMarker = async (params: MarkerSearch) => {
  const response = await request<MarkerSearch, Rows<ResponseCustomerStation>>({
    method: "get",
    url: "/customer/stations/coordiante",
    requestParams: {
      latitude: params.latitude,
      longitude: params.longitude,
      curLatitude: params.curLatitude,
      curLongitude: params.curLongitude,
    },
  });

  return response?.data?.rows;
};
